import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'napa-arrow-down-blue-icon',
  standalone: true,
  imports: [],
  templateUrl: './arrow-down-blue-icon.component.html',
  styleUrl: './arrow-down-blue-icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArrowDownBlueIconComponent {}
