<svg
  width="10px"
  height="6px"
  viewBox="0 0 10 6"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <title>icon/arrow down</title>
  <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="field/dropdown/with-icon-no-title" transform="translate(-91, -17)">
      <g id="icon/arrow-down" transform="translate(91, 17)">
        <rect id="Rectangle" x="0" y="0" width="10" height="6"></rect>
        <polyline
          id="Path-4"
          stroke="#1A7DB8"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          points="1 1 5 5 9 1"
        ></polyline>
      </g>
    </g>
  </g>
</svg>
